import * as i from 'types';
import * as React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import Loading from 'vectors/loading.svg';
import { getBookings } from 'ducks/booking';
import { meDataSelector } from 'selectors/me';
import { selectClasses } from 'selectors/classesList';
import { useSelector, useDevice, useQueryParams, useDispatch,
  useAuthenticationUser,
} from 'services/hooks';
import { Text } from 'common/typography';
import { BookingCard } from 'modules/booking';

import { GreetingBlock } from '..';
import {
  PageWrapper, BookingCardListWrapper, HorizontalLine, Container, EmptyStateContainer, OverflowContainer, LoadingContainer,
} from './styled';

export const BookingContent = () => {
  const { queryParams } = useQueryParams();
  const { authenticated } = useAuthenticationUser();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classesLoading = useSelector((state) => state.classes.loading);
  const classes = useSelector((state) => selectClasses(state, queryParams as i.ActiveClassesFilterRecord));
  const userData = useSelector(meDataSelector);
  const { isMobile } = useDevice();

  React.useEffect(() => {
    if (authenticated) dispatch(getBookings());
  }, [authenticated]);

  return (
    <PageWrapper>
      <Container>
        {!isMobile &&
          <GreetingBlock firstName={userData?.firstName} />
        }
        <BookingCardListWrapper>
          <HorizontalLine />
          <OverflowContainer>
            {classesLoading ? (
              <LoadingContainer>
                <Loading />
              </LoadingContainer>
            ) : (
              <>
                {classes && classes.length ? classes.map((item) => (
                  <BookingCard item={item} key={item.id} />
                )) : (
                  <EmptyStateContainer>
                    <Text
                      $size={30}
                      $lineHeight="40"
                    >
                      {t('There are currently no workouts with your preferences. Try another time!')}
                    </Text>
                  </EmptyStateContainer>
                )}
              </>
            )}
          </OverflowContainer>
        </BookingCardListWrapper>
      </Container>
    </PageWrapper>
  );
};
