import * as i from 'types';
import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { useDevice, useSelector, useQueryParams } from 'services/hooks';
import { selectFilterOptions } from 'selectors/classesList';
import { selectSiteStudios } from 'selectors/sites';
import { Modal } from 'common/layout';
import { Text } from 'common/typography';
import { Button } from 'common/interaction';
import { getLocalData, removeLocalData, setLocalData } from 'services';

import { BookingFilterSection } from '..';
import { daypartOptions } from './daypartOptions';
import {
  FilterContainer, Header, CloseIcon, IconContainer, Footer, ExtendedButton,
  HorizontalLine,
} from './styled';

export const FilterModal: React.FC<FilterModalProps> = ({ isModalOpen, handleCloseModal }) => {
  // Not using fields that have localisation so the language can be hardcoded,
  // because variables are not accepted in useStaticQuery
  const conceptsData = useStaticQuery<GatsbyTypes.ConceptPagesQuery>(graphql`
    query ConceptPages {
      allContentfulTypeConcept(filter: {node_locale: { eq: "nl" }}) {
        edges {
          node {
            id
            title
            zingfitId
            additionalZingfitIds
          }
        }
      }
    }
  `);

  const { t } = useTranslation();
  const filterOptions = useSelector(selectFilterOptions);
  const filterOptionsStudios = useSelector(selectSiteStudios) || [];
  const filterOptionsClassTypes = React.useMemo(() => {
    if (!conceptsData.allContentfulTypeConcept.edges) return [];

    // Classtypes are Type:Concept from contentful. In zingfit there can be multiple classtypes
    // with the same name and they need to be grouped, so in contentful you can add 'additional ids'.
    // If the concept should not show up as filter, they will use id '0' in contentful
    return conceptsData.allContentfulTypeConcept.edges
      .filter((conceptNode) => conceptNode.node.zingfitId !== '0')
      .map((conceptNode) => {
        const data = conceptNode.node;
        const additionalZingfitIds = data.additionalZingfitIds || [];
        const zingfitIds = [data.zingfitId, ...additionalZingfitIds];

        return ({ id: zingfitIds.join(','), name: data.title });
      });
  }, [conceptsData]);
  const { isDesktop } = useDevice();
  const { setQueryParams, queryParams } = useQueryParams();
  const [localFilters, setLocalFilters] = React.useState<i.ActiveClassesFilterRecord>(queryParams);

  React.useEffect(() => {
    const localFilters = getLocalData('filters');
    const filters = localFilters && JSON.parse(localFilters);

    if (filters) {
      setLocalFilters(filters);
      applyFilters(filters);
    }
  }, []);

  const selectFilterOption = (type: keyof i.ActiveClassesFilterRecord, value: string) => {
    const array = localFilters?.[type];

    const filters = {
      ...queryParams,
      ...localFilters,
      [type]: array?.includes(value)
        ? array?.filter((filter) => filter !== value)
        : [...(array || []), value],
    };

    setLocalFilters(filters);
  };

  const applyFilters = (testFilters: i.ActiveClassesFilterRecord) => {
    setQueryParams(testFilters);

    if (localFilters.date) delete testFilters.date;
    setLocalData('filters', JSON.stringify(testFilters));
    handleCloseModal();
  };

  const resetFilters = () => {
    setQueryParams({ date: localFilters.date || [] });
    removeLocalData('filters');
    handleCloseModal();
    setLocalFilters({});
  };

  const mobileVariant = {
    open: {
      opacity: 1,
      x: 0,
    },
    closed: {
      opacity: 0,
      x: '-100%',
    },
  };

  const desktopVariant = {
    open: {
      opacity: 1,
      x: '-75%',
    },
    closed: {
      opacity: 0,
      x: '-100%',
    },
  };

  const animationVariant = isDesktop ? desktopVariant : mobileVariant;

  return (
    <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
      <FilterContainer
        animate={isModalOpen ? 'open' : 'closed'}
        initial="open"
        variants={animationVariant}
        key="filterContainer"
      >
        <IconContainer onClick={handleCloseModal}>
          <CloseIcon />
        </IconContainer>
        <Header>
          <Text
            $size={30}
            $weight={400}
            $lineHeight="32"
          >
            {t('filters')}
          </Text>
        </Header>
        <HorizontalLine />
        <>
          {filterOptionsStudios && (
            <BookingFilterSection
              title={t('studios')}
              filterOptions={filterOptionsStudios}
              type="studios"
              selectFilterOption={selectFilterOption}
              activeOptions={localFilters.studios}
            />
          )}
          <BookingFilterSection
            title={t('time')}
            filterOptions={daypartOptions}
            type="dayparts"
            selectFilterOption={selectFilterOption}
            activeOptions={localFilters.dayparts}
          />
          {filterOptions?.classTypes && (
            <BookingFilterSection
              title={t('classtype')}
              filterOptions={filterOptionsClassTypes}
              type="classTypes"
              selectFilterOption={selectFilterOption}
              activeOptions={localFilters.classTypes}
            />
          )}
          {filterOptions?.instructors && (
            <BookingFilterSection
              title={t('trainers')}
              filterOptions={filterOptions?.instructors?.sort((a, b) => a.name.localeCompare(b.name))}
              type="instructors"
              selectFilterOption={selectFilterOption}
              activeOptions={localFilters.instructors}
            />
          )}
        </>
        <Footer>
          <Button onClick={resetFilters}>
            {t('clear')}
          </Button>
          <ExtendedButton
            variant="CTA"
            size="fullWidth"
            onClick={() => applyFilters(localFilters)}
          >
            {t('apply')}
          </ExtendedButton>
        </Footer>
      </FilterContainer>
    </Modal>
  );
};

type FilterModalProps = {
  isModalOpen: boolean;
  handleCloseModal: () => void;
};
